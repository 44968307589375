<template>
  <van-nav-bar
  title="基本信息管理"
  left-arrow
  @click-left="onGoBack"
  />
  <div>
    <van-form @submit="modifyuserinfo" class='register'>
      <van-field
        v-model="name"
        type="text"
        name="name"
        size=large
        label="姓名："
        placeholder="输入姓名"
        :rules="[{ required: true, message: '请输入姓名' }]"
        />
      <van-field
        v-model="id_card"
        type="text"
        name="id_card"
        size=large
        label="身份证号："
        placeholder="输入18位身份证号"
        :rules="[{ validator:validatorpassword,required: true, message: '请输入密码' }]"
        />
      
    <van-button :disabled='isdisable' :loading='isloading' type="primary" round block style="width:50%;margin:50px auto 0 auto" native-type="submit" >保存修改</van-button>
    </van-form>
  </div>
</template>

<script>
import { ref,reactive,getCurrentInstance,onMounted} from 'vue';
import { useRouter,useRoute } from 'vue-router'
import { Toast } from 'vant';
import { useUserStore } from '@/store/user.ts'

const base_url=process.env.VUE_APP_API_PORT_BASE_URL
export default {
  components: {
    [Toast.name]: Toast,
  },

  setup() {
    const  name=ref('');
    const  id_card = ref('');
    
    const  isdisable = ref(false);
    const  isloading = ref(false);
   
   const router = useRouter()
    

    let { proxy } = getCurrentInstance();
    const userStore = useUserStore()
    
    const modifyuserinfo=()=>{
      
      isdisable.value = true;
      isloading.value = true;

      let params = {data: {}}

      params={
        'id':userStore.userInfo.id,
      }
      if (name.value!=''){
        params.userName=name.value
      }
      if (id_card.value!=''){
        params.userIdCard=id_card.value
      }
      console.log(params)
      let tokens={'Authorization':userStore.userInfo.token}
      proxy.$axios.post(base_url+'/user/modifyUserInfo',params,{headers:tokens})
      .then(res=>{
        if(res.data.code==200){
          Toast(res.data.msg);
          router.push('/home');
        }else{
           Toast('修改信息失败，确认是否正确。');
           name.value='';
           id_card.value='';
           isdisable.value = false;
           isloading.value = false;
        }
      })
    };

    return {
      name,
      id_card,
      isdisable,
      isloading,
      modifyuserinfo,
    };
  }

  
};
</script>

<style lang="less">

.edit-icon {
    font-size: 16px;
    line-height: inherit;
  }

</style>
